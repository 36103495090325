@import "src/assets/scss/variables/_variables.scss";
/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import "~flatpickr/dist/flatpickr.min.css";

.link {
  // color: rgb(var(--bs-primary-rgb));
  cursor: pointer;
  &:hover {
    color: rgba(var(--bs-primary-rgb)) !important;
  }
}

.white {
  background-color: white !important;
}
.grey {
  background-color: #babfc7;
  color: white;
}
.error {
  color: #ea5455 !important;
}

.registration-steps .bs-stepper-header {
  display: none !important;
}

.help-text {
  color: #8b84a3;
}
.top-right-button {
  position: absolute;
  top: 0;
  right: 0;
}
.dropzone {
  cursor: pointer;
  background-color: #f8f8f8;
  border: 1px dashed rgb(var(--bs-primary-rgb));
  border-radius: 0.357rem;
  display: flex;
  min-height: 300px;
  align-items: "center";
  justify-content: center;
}

.flex-1 {
  flex: 1;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.box {
  background-color: #f8f8f8;
  border-radius: 9px;
}
.box-primary {
  background-color: rgb(179, 237, 220);
  border-radius: 9px;
}
.cursor-ponter {
  cursor: pointer;
}
.elipsis-wrapper {
  padding: 5px;
  overflow: hidden;
  // position: relative;
}

.elipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.link-wrapper {
  width: 100%;
  height: 100%;
}
.header-navbar {
  z-index: 201 !important;
}
.modal {
  z-index: 1300 !important;
}
.modal-content {
  background-color: white;
}
.popover {
  z-index: 200;
}

.modal-dialog {
  max-width: 700px;
}

.dropdown-menu {
  background-color: white;
  .list-item.new {
    background: #7367f012;

    &:hover {
      background: #7367f016 !important;
    }
  }
}

.avatar img {
  background-size: cover;
}

.avatar {
  &.avatar-lg {
    .avatar-content {
      font-size: 1.5rem !important;
    }
  }
  &.avatar-xl {
    .avatar-content {
      font-size: 2.2rem !important;
    }
  }
}

.react-select.last .select__menu {
  position: relative !important;
}

.avatar-status-lg {
  width: 11px !important;
  height: 11px !important;
  border-width: 1px !important;
}

.navbar-container {
  ul.navbar-nav {
    li {
      > div.nav-link {
        color: $primary;
        padding: 0 0.5rem;
        position: relative;
      }
    }
    div.dropdown-user-link {
      display: flex;
      align-items: center;
    }
  }
}

.chat-application {
  .content-area-wrapper {
    border: none !important;
  }
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: $primary;
  border-color: $primary;
}

@media only screen and (max-width: 1050px) {
  .modal .modal-dialog {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
