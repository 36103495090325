//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

// here update color and other bs variables

$purple: #25cd95 !default; //$primary
$green: #28c76f !default; //$success
$blue: #00cfe8 !default; //$info
$orange: #ff9f43 !default; //$warning
$red: #ea5455 !default; //$danger

$quantumColor: #25cd95 !default; //$primary
$green: #28c76f !default; //$success

$primary: $quantumColor !default;

$primary10: #25cd9510 !default;
$primary20: #25cd9520 !default;
$primary30: #25cd9530 !default;
$primary40: #25cd9540 !default;
$primary50: #25cd9550 !default;
$primary60: #25cd9560 !default;
$primary70: #25cd9570 !default;
$primary80: #25cd9580 !default;
$primary90: #25cd9590 !default;

$primaryTransparent: #b3eddc !default;
