//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding component variables.
//  ================================================================================================

// Select Menu
.select__menu,
.react-select__menu {
  .select__menu-list,
  .react-select__menu-list {
    .select__option,
    .react-select__option {
      &.select__option--is-disabled {
        cursor: unset;
        background-color: $gray-300;
        color: $white;
      }
    }
  }
}
